<template>
  <div :style="{ minHeight, height }" class="view-wrapper__container">
    <NoticeBanner v-if="!isScreenshot"/>

    <div v-if="!isScreenshot" class="view-loading-indicator">
      <div v-if="isLoading">
        <UProgress animation="carousel"/>
      </div>
    </div>

    <div :class="{ '--screenshot': isScreenshot }" class="view-wrapper">
      <main>
        <div v-if="!hideAd && !isScreenshot" :class="{ '--dev': isDev }" class="view-wrapper__ad-container">
          <NonAdminOnly>
            <FuseAd id="page-banner" fuseId="23127817360"/>
          </NonAdminOnly>

          <div v-if="isDev" class="view-wrapper__ad-container-text">
            Ad Placeholder
          </div>
        </div>

        <slot/>
      </main>
    </div>

    <transition>
      <Backdrop v-if="backdropImage" :key="backdropImage" :blur="backdropBlur" :img="backdropImage"/>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useUserStore } from "~/stores/UserStore";

interface Props {
  isLoading?: boolean;
  hideLoadingOverlay?: boolean;
  backdropBlur?: string;
  backdropImage?: string;
  minHeight?: string;
  height?: string;
  hideAd?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  backdropBlur: '0px'
});

const route = useRoute();
const isScreenshot = computed(() => route.query && route.query.mode && route.query.mode === 'screenshot');

const minHeight = props.minHeight ? `max(calc(100vh - var(--Nav-Height) - var(--Footer-Height)), ${props.minHeight})` : 'calc(100vh - var(--Nav-Height) - var(--Footer-Height))';

const userStore = useUserStore();

const config = useRuntimeConfig();
const isDev = computed(() => config.public.environment === 'development' || userStore.adminMode);
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.view-loading-indicator {
  height: 0.6rem;
}

.view-wrapper__container {
  position: relative;
  display: flex;
  flex-direction: column;

  .view-wrapper {
    display: flex;
    flex: 1;
    background-color: var(--UI-Primary);
    overflow: hidden;
    padding: 2rem 5% 5rem 5%;

    main {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: var(--View-MaxWidth);
      margin: 0 auto;
      width: 100%;
      z-index: 10;

      .view-wrapper__ad-container {
        position: relative;
        min-height: 9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.6rem;

        &.--dev {
          background-color: rgba(0, 0, 0, 0.2)
        }

        .view-wrapper__ad-container-text {
          position: absolute;
          inset: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 900;
          font-size: 2.4rem;
        }
      }
    }

    &.--screenshot {
      padding: 1rem 2rem;
      min-height: 100vh;
    }
  }
}
</style>