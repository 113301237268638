export interface TFTSet {
  name: string;
  setCoreName: string;
  setUrlName: string;
  number: number;
  minPatch: string;
  maxPatch: string;
}

export const TFT_TUTORIAL: TFTSet = {
  name: "Tutorial",
  setCoreName: 'TFTTutorial',
  setUrlName: "Tutorial",
  number: 0,
  minPatch: "9.14",
  maxPatch: "latest"
};

export const TFT_SET_1: TFTSet = {
  name: "Faction Wars",
  setCoreName: "TFTSet1",
  setUrlName: "Set1",
  number: 1,
  minPatch: "9.14",
  maxPatch: "9.21"
};

export const TFT_SET_2: TFTSet = {
  name: "Rise of the Elements",
  setCoreName: "TFTSet2",
  setUrlName: "Set2",
  number: 2,
  minPatch: "9.22",
  maxPatch: "10.5"
};

export const TFT_SET_3: TFTSet = {
  name: "Galaxies",
  setCoreName: "TFTSet3",
  setUrlName: "Set3",
  number: 3,
  minPatch: "10.6",
  maxPatch: "10.11"
};

export const TFT_SET_3_5: TFTSet = {
  name: "Galaxies: Return to the Stars",
  setCoreName: "TFTSet3_2",
  setUrlName: "Set3.5",
  number: 3.5,
  minPatch: "10.12",
  maxPatch: "10.18"
};

export const TFT_SET_4: TFTSet = {
  name: "Fates",
  setCoreName: "TFTSet4",
  setUrlName: "Set4",
  number: 4,
  minPatch: "10.19",
  maxPatch: "11.1"
};

export const TFT_SET_4_5: TFTSet = {
  name: "Fates: Festival of Beasts",
  setCoreName: "TFTSet4_2",
  setUrlName: "Set4.5",
  number: 4.5,
  minPatch: "11.2",
  maxPatch: "11.8"
};

export const TFT_SET_5: TFTSet = {
  name: "Reckoning",
  setCoreName: "TFTSet5",
  setUrlName: "Set5",
  number: 5,
  minPatch: "11.9",
  maxPatch: "11.14"
};

export const TFT_SET_5_5: TFTSet = {
  name: "Reckoning: Dawn of Heroes",
  setCoreName: "TFTSet5_2",
  setUrlName: "Set5.5",
  number: 5.5,
  minPatch: "11.15",
  maxPatch: "11.21"
};

export const TFT_SET_6: TFTSet = {
  name: "Gizmos & Gadgets",
  setCoreName: "TFTSet6",
  setUrlName: "Set6",
  number: 6,
  minPatch: "11.22",
  maxPatch: "12.3"
};

export const TFT_SET_6_5: TFTSet = {
  name: "Gizmos & Gadgets: Neon Nights",
  setCoreName: "TFTSet6_2",
  setUrlName: "Set6.5",
  number: 6.5,
  minPatch: "12.4",
  maxPatch: "12.10"
};

export const TFT_SET_7: TFTSet = {
  name: "Dragonlands",
  setCoreName: "TFTSet7",
  setUrlName: "Set7",
  number: 7,
  minPatch: "12.11",
  maxPatch: "12.16"
};

export const TFT_SET_7_5: TFTSet = {
  name: "Dragonlands: Uncharted Realms",
  setCoreName: "TFTSet7_2",
  setUrlName: "Set7.5",
  number: 7.5,
  minPatch: "12.17",
  maxPatch: "12.22"
};

export const TFT_SET_8: TFTSet = {
  name: "Monsters Attack",
  setCoreName: "TFTSet8",
  setUrlName: "Set8",
  number: 8,
  minPatch: "12.23",
  maxPatch: "13.5"
};

export const TFT_SET_8_5: TFTSet = {
  name: "Monsters Attack - Glitched Out",
  setCoreName: "TFTSet8_2",
  setUrlName: "Set8.5",
  number: 8.5,
  minPatch: "13.6",
  maxPatch: "13.11"
};

export const TFT_SET_9: TFTSet = {
  name: "Runeterra Reforged",
  setCoreName: "TFTSet9",
  setUrlName: "Set9",
  number: 9,
  minPatch: "13.12",
  maxPatch: "13.18"
};

export const TFT_SET_9_5: TFTSet = {
  name: "Runeterra Reforged: Horizon Bound",
  setCoreName: "TFTSet9_2",
  setUrlName: "Set9.5",
  number: 9.5,
  minPatch: "13.18",
  maxPatch: "13.22"
};

export const TFT_SET_10: TFTSet = {
  name: "Remix Rumble",
  setCoreName: "TFTSet10",
  setUrlName: "Set10",
  number: 10,
  minPatch: "13.23",
  maxPatch: "14.5"
};

export const TFT_SET_11: TFTSet = {
  name: "Inkborn Fables",
  setCoreName: "TFTSet11",
  setUrlName: "Set11",
  number: 11,
  minPatch: "14.6",
  maxPatch: "14.14"
};

export const TFT_SET_12: TFTSet = {
  name: "Magic n' Mayhem",
  setCoreName: "TFTSet12",
  setUrlName: "Set12",
  number: 12,
  minPatch: "14.15",
  maxPatch: "14.22"
};

export const TFT_SET_13: TFTSet = {
  name: "Into The Arcane",
  setCoreName: "TFTSet13",
  setUrlName: "Set13",
  number: 13,
  minPatch: "14.23",
  maxPatch: "latest",
};