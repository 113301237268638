<template>
  <Teleport to="body">
    <div v-if="isOpen" ref="target" :style="menuStyle" class="context-menu">
      <div v-for="optionGroup in options" class="context-menu__option-group">
        <div v-for="option in optionGroup" class="context-menu__option" @click="clickOption(option)">
          <div class="context-menu__option-icon">
            <UIcon v-if="option.icon" :name="option.icon"/>

            <div v-else-if="option.image" class="context-menu__option-image">
              <TeamfightImage :alt="option.label" :src="option.image" :style="{height: 'auto', width: '100%'}"/>
            </div>
          </div>

          <div class="context-menu__option-label">
            {{ option.label }}
          </div>

          <div v-if="option.subOptions?.length">
            <UIcon name="i-heroicons-chevron-right-20-solid"/>
          </div>

          <div v-if="option.subOptions?.length" class="context-menu__sub-menu">
            <div v-for="subOption in option.subOptions" class="context-menu__option" @click="clickOption(subOption)">
              <div class="context-menu__option-icon">
                <UIcon v-if="subOption.icon" :name="subOption.icon"/>

                <div v-else-if="subOption.image" class="context-menu__option-image">
                  <TeamfightImage
                      :alt="subOption.label" :src="subOption.image" :style="{height: 'auto', width: '100%'}"
                  />
                </div>
              </div>

              <div class="context-menu__option-label">
                {{ subOption.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { ContextMenuOption, useContextMenu } from "~/stores/ContextMenuStore";

const store = useContextMenu();
const { positionY, positionX, options } = storeToRefs(store);

const isOpen = computed(() => !!positionY.value && !!positionX.value && options.value.length > 0);
const menuStyle = computed(() => isOpen.value ? { top: `${positionY.value}px`, left: `${positionX.value}px` } : {});

const clickOption = async (option: ContextMenuOption) => {
  if (option.action) {
    option.action();
  }

  store.closeContextMenu();
};

const target = ref(null);
onClickOutside(target, () => store.closeContextMenu());
</script>

<style lang="scss" scoped>
.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--UI-Secondary);
  border: 0.1rem solid var(--UI-Border);
  border-radius: 0.3rem;
  z-index: 1000;

  .context-menu__option-group {
    display: flex;
    flex-direction: column;
    border-top: 0.1rem solid var(--UI-Border);

    &:first-child {
      border-top: none;
    }

    .context-menu__option {
      position: relative;
      display: flex;
      gap: 1rem;
      padding: 1rem;
      transition: 0.25s ease all;
      cursor: pointer;
      fill: white;
      font-size: 1.3rem;
      font-weight: var(--FontWeight-Medium);
      min-width: 15rem;

      &:hover {
        background-color: var(--UI-Border);
        fill: var(--UI-Primary);

        &.context-menu__option-label {
          color: var(--UI-Primary);
        }
      }

      .context-menu__option-icon {
        fill: white;

        .context-menu__option-image {
          height: 1.4rem;
          width: 1.4rem;
        }
      }

      .context-menu__option-label {
        flex: 1;
      }

      .context-menu__sub-menu {
        position: absolute;
        opacity: 0;
        left: 100%;
        top: 0;
        display: flex;
        flex-direction: column;
        background-color: var(--UI-Secondary);
        border: 0.1rem solid var(--UI-Border);
        border-radius: 0.3rem;
        z-index: 1010;

      }

      &:hover {
        .context-menu__sub-menu {
          opacity: 1;
        }
      }
    }
  }
}
</style>